/**
 * Tab Nhóm khác
 */
import { useState, useEffect } from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import {
  Form,
  Row,
  Col,
  Button,
  Popconfirm,
  Select,
  Table,
  Layout,
  Typography,
  notification,
  Switch,
  Input,
  Space,
  Tabs,
} from "antd";
import CustomLabelValue from "pages/components/CustomLabelValue";
import { PlusOutlined, ArrowLeftOutlined } from "@ant-design/icons";
const { Content } = Layout;
import type { ColumnsType } from "antd/es/table";
const { Search } = Input;
import moment from "moment";
import { useHistory } from "react-router";
import {} from "antd";
import { closeModal } from "utils";
import { ModalInfoType, PayloadType } from "type";
import { styled } from "styled-components";
import { useDispatch } from "react-redux";
import { setModal } from "features/modalSlice";
import { useSelector } from "react-redux";
import _ from "lodash";
import { getPromotionDetails, setQuery } from "features/promotionDetailSlice";
import queryString from "query-string";
import CUPromotion from "./CUContract";
const { Title } = Typography;
interface DataType {
  phone: string;
  name: string;
  nameForPig: string;
  farmName: string;
  farmTypeId: string;
  farmScaleId: string;
  email: string;
  personInChargeId: string;
  address: string;
  provinceId: string;
  servicePackageId: string;
  serviceTimeId: string;
}
const CustomPopconfirm = styled(Popconfirm)`
  .ant-popover-inner {
    max-width: 240px !important;
    background-color: red;
  }
`;
import { useForm } from "antd/lib/form/Form";
import TabReadContract from "./tabRContract/TabReadContract";
import GeneralInfo from "./tabRContract/GeneralInfo";
import Service from "./tabRContract/Service";
import Subscription from "./tabRContract/Subscription";
import Support from "./tabRContract/Support";
function RContract(dataView: any) {
  const record = dataView?.record;
  const dispatch = useDispatch();
  const history = useHistory();
  const { query } = useSelector((state: any) => state.promotionDetailReducer);
  const [listPromotionDetail, setListPromotionDetail] = useState<any>([]);
  console.log("record chi tiết", record);
  //Lấy danh sách chi tiết khuyến mãi theo id khuyến mãi

  const optionTabContracts = [
    {
      key: `1`,
      label: `Thông tin chung`,
      children: <GeneralInfo data={record} />,
    },
    {
      key: `2`,
      label: `Thuê bao`,
      children: <Service data={record} activeKey={dataView?.activeKey} />,
    },
    
    {
      key: `4`,
      label: `Hỗ trợ`,
      children: <Support data={record} />,
    },
  ];
  return (
    //
    <div style={{ padding: 20 }}>
      <Tabs defaultActiveKey="1" items={optionTabContracts} />
    </div>
  );
}

export default RContract;
